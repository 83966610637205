@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    label {
        @apply block mb-1;
    }

    input,
    select,
    textarea {
        @apply rounded border focus:border-black outline-none border-gray-300 px-3 w-full h-9 text-base;
    }

    textarea {
        @apply py-3;
    }

    a {
        @apply text-sky-500 hover:text-sky-600 hover:underline;
    }

    h1 {
        @apply font-semibold text-3xl;
    }

    h2 {
        @apply font-semibold text-2xl;
    }

    h3 {
        @apply font-semibold text-xl;
    }

    h4 {
        @apply font-semibold text-lg;
    }
}
